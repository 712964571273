// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-outils-wagner-podcast-index-js": () => import("./../../../src/pages/outils/wagner/podcast/index.js" /* webpackChunkName: "component---src-pages-outils-wagner-podcast-index-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-spectacle-js": () => import("./../../../src/templates/spectacle.js" /* webpackChunkName: "component---src-templates-spectacle-js" */)
}

